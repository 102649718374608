<template>
    <Layout>
        <h4>Security Deposit Refund Request</h4>
        <br />
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="requestRefund"
            ref="formContainer"
        >
            <div class="row" v-if="this.role === 'Owner'">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Type*</label>
                        <b-form-radio-group
                            v-model="refund.sd_type"
                            :options="depositOptions"
                            class="mb-3"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                            :class="{
                                'is-invalid':
                                    submitted && $v.refund.sd_type.$error,
                            }"
                        ></b-form-radio-group>

                        <div
                            v-if="submitted && $v.refund.sd_type.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.refund.sd_type.required"
                                >Please choose an option</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="refund.unit_id"
                            :options="mappedUserUnits"
                            :class="{
                                'is-invalid':
                                    submitted && $v.refund.unit_id.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.refund.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.refund.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname"
                            >Securiy Deposit Receipt Number*</label
                        >
                        <input
                            v-model="refund.receipt_no"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.refund.receipt_no.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.refund.receipt_no.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.refund.receipt_no.required"
                                >Receipt number required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname"> Date Of Deposit*</label>
                        <b-form-datepicker
                            v-model="refund.date_of_deposit"
                            :max="max"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.refund.date_of_deposit.$error,
                            }"
                        ></b-form-datepicker>

                        <div
                            v-if="submitted && $v.refund.date_of_deposit.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.refund.date_of_deposit.required"
                                >Date is required
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" v-if="refund.sd_type == 1">
                    <label for="ownerfname">Title Deed Copy*</label>

                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleTitleDeed"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.refund.title_deed.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.refund.title_deed.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.refund.title_deed.required"
                            >Title deed is required</span
                        >
                    </div>
                </div>
                <div class="col-lg-6" v-if="refund.sd_type == 2">
                    <div class="form-group">
                        <label for="ownerfname">Nature of work*</label>
                        <input
                            v-model="refund.nature_of_work"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.refund.nature_of_work.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.refund.nature_of_work.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.refund.nature_of_work.required"
                                >Nature of work is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="refund.sd_type == 2">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Contractor name*</label>
                        <input
                            v-model="refund.contractor_name"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.refund.contractor_name.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.refund.contractor_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.refund.contractor_name.required"
                                >Contractor name required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname"> Date of starting work*</label>
                        <b-form-datepicker
                            v-model="refund.date_of_starting_work"
                            :max="max"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.refund.date_of_starting_work.$error,
                            }"
                        ></b-form-datepicker>

                        <div
                            v-if="
                                submitted &&
                                $v.refund.date_of_starting_work.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="!$v.refund.date_of_starting_work.required"
                                >Date is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6" v-if="refund.sd_type == 2">
                    <div class="form-group">
                        <label for="ownerfname"> Date of completion*</label>
                        <b-form-datepicker
                            v-model="refund.date_of_completion"
                            :max="max"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.refund.date_of_completion.$error,
                            }"
                        ></b-form-datepicker>

                        <div
                            v-if="
                                submitted && $v.refund.date_of_completion.$error
                            "
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.refund.date_of_completion.required"
                                >Date is required
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname"
                        >Original Security Deposit Receipt*</label
                    >
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleReceipt"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.refund.security_deposit_receipt.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted &&
                            $v.refund.security_deposit_receipt.$error
                        "
                        class="invalid-feedback"
                    >
                        <span
                            v-if="!$v.refund.security_deposit_receipt.required"
                            >Security deposit receipt is required</span
                        >
                    </div>
                </div>
            </div>

            <submitButton />
        </form>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import alertMixin from "../../../mixins/alertMixin";
import { required, requiredIf } from "vuelidate/lib/validators";
import { BASE_URL } from "../../common";
import axios from "axios";
import submitButton from "../../../components/submitButton.vue";

export default {
    mixins: [alertMixin],
    components: {
        Layout,
        submitButton,
    },
    validations: {
        refund: {
            sd_type: {
                required: requiredIf(function () {
                    return this.role === "Tenant";
                }),
            },
            unit_id: { required },
            receipt_no: { required },
            date_of_deposit: { required },
            nature_of_work: {
                required: requiredIf(function () {
                    return this.refund.sd_type == 2;
                }),
            },
            contractor_name: {
                required: requiredIf(function () {
                    return this.refund.sd_type == 2;
                }),
            },
            date_of_starting_work: {
                required: requiredIf(function () {
                    return this.refund.sd_type == 2;
                }),
            },
            date_of_completion: {
                required: requiredIf(function () {
                    return this.refund.sd_type == 2;
                }),
            },
            title_deed: {
                required: requiredIf(function () {
                    return this.refund.sd_type == 1;
                }),
            },
            security_deposit_receipt: { required },
        },
    },
    data() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
        const maxDate = new Date(today);
        return {
            role: "",
            depositType: null,
            submitted: false,
            max: maxDate,
            depositOptions: [
                { item: 1, name: "Title Deed" },
                { item: 2, name: "Maintenance" },
            ],
            payment: "",
            refund: {
                unit_id: "",
                contractor_name: "",
                date_of_completion: "",
                date_of_deposit: "",
                date_of_starting_work: "",
                nature_of_work: "",
                receipt_no: "",
                sd_type: "",
                security_deposit_receipt: "",
                security_deposit_receipt_filename: "",
                title_deed: "",
                title_deed_filename: "",
            },
        };
    },
    mounted() {
        this.role = localStorage.cs_user_role;
        if (this.role === "Tenant") {
            console.log("testing");
            this.refund.sd_type = 2;
        }
    },
    methods: {
        requestRefund() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                console.log(this.refund);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}securitydepositrefunds?token=${localStorage.cs_user_token}`,
                            this.refund
                        )
                        .then((res) => {
                            console.log(res.data.response);
                            this.successMsg(
                                res.data.response.ref_no,
                                "Success! Your request has been received by the Customer Service Team."
                            );
                            loader.hide();
                            this.resetFields();
                            this.role = localStorage.cs_user_role;
                            if (this.role === "Tenant") {
                                this.refund.sd_type = 2;
                            }
                            this.$router.push("/");
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
        handleTitleDeed(e) {
            let reader = new FileReader();
            this.refund.title_deed_filename = e.target.files[0].name;

            reader.onload = (e) => {
                this.refund.title_deed = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },
        handleReceipt(e) {
            let reader = new FileReader();
            this.refund.security_deposit_receipt_filename =
                e.target.files[0].name;

            reader.onload = (e) => {
                this.refund.security_deposit_receipt =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },
    },
    computed: {
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
    },
};
</script>
